import { MainNavScreens } from "breadcommon";
import { MAIN_NAV_SCREEN_VALUES } from "breadcommon";
import { createContext, useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";

type UrlInfo = {
  currentTab: MainNavScreens | null;
};

export const UrlContext = createContext<UrlInfo>({
  currentTab: null,
});

export function UrlContextProvider(props: {
  children: JSX.Element;
}): JSX.Element {
  const [urlInfo, setUrlInfo] = useState<UrlInfo>({
    currentTab: null,
  });
  const location = useLocation();

  useEffect(() => {
    const urlMatch = matchPath("/:currentTab", location.pathname);
    const currentTabString = urlMatch?.params.currentTab?.toUpperCase();
    const currentTab =
      MAIN_NAV_SCREEN_VALUES.find((val) => val === currentTabString) ?? null;

    setUrlInfo({
      currentTab: currentTab,
    });
  }, [location]);

  return (
    <UrlContext.Provider value={urlInfo}>{props.children}</UrlContext.Provider>
  );
}
