import { useContext } from "react";
import { TransactionsContext } from "../firebaseio/TransactionsContext";
import dayjs from "dayjs";
import { CategoriesContext } from "../firebaseio/CategoriesContext";
import { AnalyzeReportTable, newReport } from "./ReportTable";
import cardWithTabsStyles from "../common/CardWithTabs.module.scss";
import { CategoryType } from "breadcommon";

export function SliceAndDiceScreen() {
  const categories = useContext(CategoriesContext);
  const transactions = useContext(TransactionsContext);

  const report = newReport(
    dayjs().startOf("year"),
    dayjs().endOf("year"),
    null,
    Array.from(categories.values()).filter(
      (category) => category.type !== CategoryType.Silent
    ),
    Array.from(transactions.values())
  );

  return (
    <div className={cardWithTabsStyles.reportLowerCard}>
      <AnalyzeReportTable report={report} />
    </div>
  );
}
