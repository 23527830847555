import * as z from "zod";

// These are defined by Plaid and need to be lowercase to match Plaid returns.
// See https://plaid.com/docs/api/accounts/#investment-transaction-types-schema
export enum InvestmentTransactionType {
  Buy = "buy",
  Sell = "sell",
  Cancel = "cancel",
  Cash = "cash",
  Fee = "fee",
  Transfer = "transfer",
}

// From https://plaid.com/docs/api/products/transactions/#transactionssync
const plaidDataStandardTransactionSchema = z.object({
  account_id: z.string(),
  amount: z.number(),
  iso_currency_code: z.string().nullable(),
  unofficial_currency_code: z.string().nullable(),
  category: z.array(z.string()).nullable(), // deprecated
  category_id: z.string().nullable(), // deprecated
  check_number: z.string().nullable().optional(),
  date: z.string(),
  location: z.object({
    address: z.string().nullable(),
    city: z.string().nullable(),
    region: z.string().nullable(),
    postal_code: z.string().nullable(),
    country: z.string().nullable(),
    lat: z.number().nullable(),
    lon: z.number().nullable(),
    store_number: z.string().nullable(),
  }),
  name: z.string(),
  merchant_name: z.string().nullable().optional(),
  payment_meta: z.object({
    reference_number: z.string().nullable(),
    ppd_id: z.string().nullable(),
    payee: z.string().nullable(),
    by_order_of: z.string().nullable(),
    payer: z.string().nullable(),
    payment_method: z.string().nullable(),
    payment_processor: z.string().nullable(),
    reason: z.string().nullable(),
  }),
  pending: z.boolean(),
  pending_transaction_id: z.string().nullable(),
  account_owner: z.string().nullable(),
  transaction_id: z.string(),
  transaction_type: z.string().optional(), // deprecated
  logo_url: z.string().nullable().optional(),
  website: z.string().nullable().optional(),
  authorized_date: z.string().nullable(),
  authorized_datetime: z.string().nullable(),
  datetime: z.string().nullable(),
  payment_channel: z.string(),
  personal_finance_category: z
    .object({
      primary: z.string(),
      detailed: z.string(),
      confidence_level: z.string().nullable().optional(),
    })
    .nullable()
    .optional(),
  transaction_code: z.string().nullable(),
  personal_finance_category_icon_url: z.string().optional(),
  counterparties: z
    .array(
      z.object({
        name: z.string(),
        entity_id: z.string().nullable().optional(),
        type: z.string(),
        website: z.string().nullable(),
        logo_url: z.string().nullable(),
        confidence_level: z.string().nullable().optional(),
      })
    )
    .optional(),
  merchant_entity_id: z.string().nullable().optional(),
});

// From https://plaid.com/docs/api/products/investments/#investments-transactions-get-response-investment-transactions
const plaidDataInvestmentTransactionSchema = z.object({
  account_id: z.string(),
  amount: z.number(),
  cancel_transaction_id: z.string().nullable().optional(),
  date: z.string(), // like "2020-05-29"
  fees: z.number().nullable().optional(),
  investment_transaction_id: z.string(),
  iso_currency_code: z.string().nullable(),
  name: z.string().nullable(),
  price: z.number(),
  quantity: z.number(),
  security_id: z.string().nullable().optional(),
  subtype: z.string(),
  type: z.enum(Object.values(InvestmentTransactionType) as [string]),
  unofficial_currency_code: z.string().nullable().optional(),
});

const manualDataTransactionSchema = z.object({
  reviewed: z.boolean(),
  category_id: z.string().nullable(),
});

export const firestoreDocTransactionSchema = z.object({
  plaid_data: plaidDataStandardTransactionSchema.optional(),
  plaid_investment_data: plaidDataInvestmentTransactionSchema.optional(),
  manual_data: manualDataTransactionSchema,
});

export type FirestoreDocTransaction = z.infer<
  typeof firestoreDocTransactionSchema
>;
