import * as z from "zod";
import { CategoryType } from "./CategoryType";

const vs = Object.values(CategoryType);

export const firestoreDocCategorySchema = z.object({
  id: z.string(),
  name: z.string(),
  emoji: z.string(),
  type: z
    .enum([
      // Hack to make enum creation happy about taking in Object.values()
      CategoryType.Expense,
      ...Object.values(CategoryType),
    ])
    .default(CategoryType.Expense), // TODO: remove default after migration
  created_timestamp_secs: z.number(),
  order_position: z.number(),
});

export type FirestoreDocCategory = z.infer<typeof firestoreDocCategorySchema>;
