import { createContext, useContext, useRef, useState } from "react";
import { InlineCategory } from "./InlineCategory";
import styles from "./InlineTransaction.module.scss";
import { HorizontalSpacer } from "../utils/Utils";
import {
  useClickOutsideElement,
  useKeyDown,
} from "../utils/customHooks";
import { keyCodes } from "../utils/KeyCodes";
import {
  BreadTransaction,
  getNullFieldValueCriterionEquivalent,
  matchTransactionToRule,
} from "breadcommon";
import { InlineReviewed } from "./InlineReviewed";
import { UserContext } from "../firebaseio/UserContext";
import {
  firestoreAddRuleAndUpdateTransactions,
  firestoreUpdateTransaction,
} from "../firebaseio/firestoreIo";
import { DollarAmount } from "../common/DollarAmount";
import { InlineRule } from "./InlineRule";
import { FirestoreDocRule } from "breadcommon";
import { MerchantLogo } from "../common/MerchantLogo";
import { RulesContext } from "../firebaseio/RulesContext";
import dayjs from "dayjs";
import { InlineRuleEditorModal } from "./InlineRuleEditorModal";
import { InlineAccount } from "../common/InlineAccount";

export const InlineTransactionContext = createContext<{
  transactionSelected: boolean;
  ruleForFieldHighlighting: {
    get: FirestoreDocRule | null;
    set: React.Dispatch<React.SetStateAction<FirestoreDocRule | null>>;
  };
  keyListenerShouldListen: boolean,
}>({
  transactionSelected: false,
  ruleForFieldHighlighting: { get: null, set: () => { } },
  keyListenerShouldListen: false,
});

function InlineTransaction(props: {
  transaction: BreadTransaction;
}): JSX.Element {
  const [transactionSelected, setTransactionSelected] = useState(false);
  const [ruleForFieldHighlighting, setRuleForFieldHighlighting] =
    useState<FirestoreDocRule | null>(null);
  const inlineTransactionContainerRef = useRef<HTMLDivElement>(null);
  const user = useContext(UserContext);
  const rules = useContext(RulesContext);
  useClickOutsideElement(
    inlineTransactionContainerRef,
    () => setTransactionSelected(false),
    transactionSelected,
    window
  );
  const [isRuleEditorOpen, setIsRuleEditorOpen] = useState<boolean>(false);
  const keyListenerShouldListen = transactionSelected && !isRuleEditorOpen;
  useKeyDown(inlineTransactionContainerRef, keyListenerShouldListen, handleKeyDown);


  const { bestMatchRule, bestMatchRuleIsActive } = matchTransactionToRule(
    props.transaction,
    rules
  );

  let newRuleOrderPosition = 0;
  rules.forEach(
    (rule) =>
    (newRuleOrderPosition = Math.min(
      rule.order_position - 1,
      newRuleOrderPosition
    ))
  );
  function createRuleFromTransactionWithoutId(
    categoryIdAction: string,
  ): Omit<FirestoreDocRule, "id"> {
    return {
      criteria: {
        account_id: props.transaction.account_id,
        merchant_name:
          props.transaction.merchant?.name ??
          (props.transaction.type !== null
            ? null
            : getNullFieldValueCriterionEquivalent()),
        description:
          props.transaction.merchant || props.transaction.type
            ? null
            : props.transaction.description ??
            getNullFieldValueCriterionEquivalent(), // only include description if there is no merchant
        partial_description: null,
        type:
          props.transaction.type ??
          (props.transaction.merchant !== null
            ? null
            : getNullFieldValueCriterionEquivalent()),
        subtype:
          props.transaction.subtype ??
          (props.transaction.merchant !== null
            ? null
            : getNullFieldValueCriterionEquivalent()),
      },
      action: {
        category_id: categoryIdAction,
      },
      created_timestamp_secs: dayjs().unix(),
      order_position: newRuleOrderPosition,
    };
  }

  function getInlineTransactionContainerClasses() {
    let classes = styles.inlineTransactionContainer;
    if (transactionSelected) {
      classes = `${classes} ${styles.transactionHighlight}`;
    }
    return classes;
  }

  function scrollIntoView(div: HTMLDivElement) {
    div.scrollIntoView({
      block: "nearest",
      inline: "nearest",
      // behavior: "smooth",
    });
  }

  function markReviewed(): void {
    firestoreUpdateTransaction(
      user.uid,
      props.transaction.id,
      {
        manual_data: { reviewed: true },
      },
      null
    );
  }

  function selectRowAbove() {
    const prevSibling =
      inlineTransactionContainerRef.current?.previousElementSibling;
    if (transactionSelected && prevSibling instanceof HTMLDivElement) {
      setTransactionSelected(false);
      const siblingDiv = prevSibling as HTMLDivElement;
      siblingDiv.click();
      scrollIntoView(siblingDiv);
    }
  }

  function selectRowBelow() {
    const nextSibling =
      inlineTransactionContainerRef.current?.nextElementSibling;
    if (transactionSelected && nextSibling instanceof HTMLDivElement) {
      setTransactionSelected(false);
      const siblingDiv = nextSibling as HTMLDivElement;
      siblingDiv.click();
      scrollIntoView(siblingDiv);
    }
  }

  function createNewRule(
    categoryIdAction: string,
  ): void {
    if (!bestMatchRuleIsActive) {
      firestoreAddRuleAndUpdateTransactions(
        user.uid,
        createRuleFromTransactionWithoutId(categoryIdAction)
      );
    }
  }

  function handleKeyDown(event: KeyboardEvent): void {
    switch (event.code) {
      case keyCodes.ENTER:
        if (event.repeat) return;
        if (props.transaction.categoryId) {
          markReviewed();
        }
        if (event.shiftKey) {
          selectRowAbove();
        } else {
          selectRowBelow();
        }
        return;
      case keyCodes.UPARROW:
        event.preventDefault();
        const prevSibling =
          inlineTransactionContainerRef.current?.previousElementSibling;
        if (transactionSelected && prevSibling instanceof HTMLDivElement) {
          setTransactionSelected(false);
          const siblingDiv = prevSibling as HTMLDivElement;
          siblingDiv.click();
          scrollIntoView(siblingDiv);
        }
        break;
      case keyCodes.DOWNARROW:
        event.preventDefault();
        const nextSibling =
          inlineTransactionContainerRef.current?.nextElementSibling;
        if (transactionSelected && nextSibling instanceof HTMLDivElement) {
          setTransactionSelected(false);
          const siblingDiv = nextSibling as HTMLDivElement;
          siblingDiv.click();
          scrollIntoView(siblingDiv);
        }
        break;
      case keyCodes.ESC:
        event.preventDefault();
        if (event.repeat) return;
        if (transactionSelected) {
          setTransactionSelected(false);
        }
        break;
      default:
        break;
    }
  }

  let betweenFieldsSpacerWidth = 20;
  return (
    <InlineTransactionContext.Provider
      value={{
        transactionSelected: transactionSelected,
        ruleForFieldHighlighting: {
          get: ruleForFieldHighlighting,
          set: setRuleForFieldHighlighting,
        },
        keyListenerShouldListen: keyListenerShouldListen,
      }}
    >
      <div
        ref={inlineTransactionContainerRef}
        onClick={() => setTransactionSelected(true)}
        className={`${getInlineTransactionContainerClasses()}`}
      >
        <div className={styles.dateColumn}>
          <div className={styles.inlineContainer}>
            <div className={styles.inlineTextbox}>
              {props.transaction.date.format("MMM D, YYYY")}
            </div>
          </div>
        </div>
        <HorizontalSpacer width={betweenFieldsSpacerWidth}></HorizontalSpacer>
        <div className={styles.accountColumn}>
          <div
            className={`${styles.inlineContainer} ${ruleForFieldHighlighting !== null &&
              ruleForFieldHighlighting.criteria.account_id != null
              ? styles.ruleFieldCriteriaHighlight
              : ""
              }`}
          >
            <InlineAccount account_id={props.transaction.account_id} />
          </div>
        </div>
        <HorizontalSpacer width={betweenFieldsSpacerWidth}></HorizontalSpacer>
        <div className={styles.merchantColumn}>
          <div
            className={`${styles.inlineContainer} ${ruleForFieldHighlighting !== null &&
              (ruleForFieldHighlighting.criteria.merchant_name != null ||
                ruleForFieldHighlighting?.criteria.type != null ||
                ruleForFieldHighlighting?.criteria.subtype != null)
              ? styles.ruleFieldCriteriaHighlight
              : ""
              }`}
          >
            {props.transaction.merchant != null &&
              <>
                <MerchantLogo merchant={props.transaction.merchant} size={18.5} />
                <HorizontalSpacer width={15} />
                <div className={styles.inlineTextbox}>
                  {props.transaction.merchant?.name}
                </div>
              </>
            }
            {props.transaction.type != null && <span className={styles.typeToken}>{props.transaction.type}</span>}
            {props.transaction.subtype != null && <span className={styles.subtypeToken}>{props.transaction.subtype}</span>}
          </div>
        </div>
        <HorizontalSpacer width={betweenFieldsSpacerWidth}></HorizontalSpacer>
        <div className={styles.descriptionColumn}>
          <div
            className={`${styles.inlineContainer} ${ruleForFieldHighlighting !== null &&
              (ruleForFieldHighlighting.criteria.description != null ||
                ruleForFieldHighlighting.criteria.partial_description != null)
              ? styles.ruleFieldCriteriaHighlight
              : ""
              }`}
          >
            <div
              className={styles.inlineTextbox}
              title={props.transaction.description ?? "No description"}
            >
              {props.transaction.description}
            </div>
          </div>
        </div>
        <HorizontalSpacer width={betweenFieldsSpacerWidth}></HorizontalSpacer>
        <div className={styles.inlineCategoryColumn}>
          <InlineCategory
            transactionId={props.transaction.id}
            categoryId={props.transaction.categoryId}
            createNewRule={createNewRule}
          ></InlineCategory>
        </div>
        <HorizontalSpacer width={betweenFieldsSpacerWidth}></HorizontalSpacer>
        <div className={styles.inlineRulesColumn}>
          <InlineRule
            transaction={props.transaction}
            activeRule={bestMatchRuleIsActive ? bestMatchRule : null}
            createRuleFromTransactionWithoutId={
              createRuleFromTransactionWithoutId
            }
            openRuleEditor={() => setIsRuleEditorOpen(true)}
          />
          {bestMatchRule !== null && bestMatchRuleIsActive &&
            <InlineRuleEditorModal
              rule={bestMatchRule}
              transaction={props.transaction}
              isOpen={isRuleEditorOpen}
              close={() => setIsRuleEditorOpen(false)}
            />}
        </div>
        <HorizontalSpacer width={betweenFieldsSpacerWidth}></HorizontalSpacer>
        <div className={styles.inlineReviewedColumn}>
          <InlineReviewed transaction={props.transaction}></InlineReviewed>
        </div>
        <HorizontalSpacer width={betweenFieldsSpacerWidth}></HorizontalSpacer>
        <div className={styles.inlineAmountColumn}>
          <div className={styles.inlineContainer}>
            <div
              className={`${styles.inlineAmount} ${props.transaction.amount > 0 ? styles.incomeAmount : ""
                }`}
            >
              <DollarAmount
                n={props.transaction.amount}
                includeDecimals={true}
                showPlus={true}
                showMinus={true}
                showDollarSign={false}
                monospaceFont={false}
                align="right"
              />
            </div>
          </div>
        </div>
      </div>
    </InlineTransactionContext.Provider>
  );
}

export { InlineTransaction };
