// // We aren't using a typescript enum because we want to store strings in Firestore.

// export const CategoryType = {
//   Expense: "Expense",
//   Income: "Income",
//   Savings: "Savings",
//   Silent: "Silent",
// } as const;

// if (Object.keys(CategoryType).length === 0) {
//   throw "CategoryType cannot be empty.";
// }

// // For use with typescript types.
// export type CategoryType = keyof typeof CategoryType;

// IMPORTANT: The three definitions below must be kept in sync
// anytime this file is modified.

// IMPORTANT: Values cannot be removed without running a backfill job on the DB
// (because this will invalidate values in our type system that are already in
// use).

// You might be thinking:
// "this seems unnecessarily complicated... why didn't he use a typescript enum?
// Why is the same thing defined three times? What's going on here?"
//
// Well, this file should change very infrequently (if ever). And, with these
// three definitions this funnily-constructed enum becomes very easy to use
// and has very elegant typing across zod and typescript.

export const CategoryType: {
  Expense: "Expense";
  Income: "Income";
  Savings: "Savings";
  Silent: "Silent";
} = {
  Expense: "Expense",
  Income: "Income",
  Savings: "Savings",
  Silent: "Silent",
};

if (Object.keys(CategoryType).length === 0) {
  throw "CategoryType cannot be empty.";
}

// For use with typescript types.
// Must keep this up-to-date with the values defined in CategoryType above!
export type CategoryType = keyof typeof CategoryType;

// For use with enums in zod schemas (https://zod.dev/?id=zod-enums).
// Must keep this up-to-date with the values defined in CategoryType above!
export const CATEGORY_TYPE_VALUES = [
  "Expense",
  "Income",
  "Savings",
  "Silent",
] as const;
