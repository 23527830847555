import { useContext } from "react";
import { BudgetsContext } from "../firebaseio/BudgetsContext";
import { CategoriesContext } from "../firebaseio/CategoriesContext";
import { AnalyzeReportTable, newReport } from "./ReportTable";
import { TransactionsContext } from "../firebaseio/TransactionsContext";
import cardWithTabsStyles from "../common/CardWithTabs.module.scss";
import { BudgetAnalysisStateContext } from "./UnderstandingScreen";
import { VerticalSpacer } from "../utils/Utils";
import { DropDown } from "../common/DropDown";
import { PRIMARY_COLOR } from "../common/colors";
import { CategoryType } from "breadcommon";

export function BudgetTrackingScreen(props: {}) {
  const [budgetAnalysisState, setBudgetAnalysisState] = useContext(
    BudgetAnalysisStateContext
  );
  const categories = useContext(CategoriesContext);
  const transactions = useContext(TransactionsContext);
  const budgets = useContext(BudgetsContext);

  const selectedBudget = budgets.get(budgetAnalysisState.selectedBudgetId);

  if (budgets.size === 0) {
    return (
      <div>
        Looks like you don't have any budgets yet. Please create a budget in the
        Budget tab to track against it.
      </div>
    );
  }

  if (!selectedBudget) {
    return (
      <div>
        Something has gone wrong. We are unable to find the requested budget.
      </div>
    );
  }

  const report = newReport(
    selectedBudget.beginDateTimestampSecs,
    selectedBudget.endDateTimestampSecs,
    selectedBudget,
    Array.from(categories.values()).filter(
      (category) => category.type !== CategoryType.Silent
    ),
    Array.from(transactions.values())
  );

  let budgetDropDownEntries: Map<string, string> = new Map();
  Array.from(budgets.values())
    .sort(
      (a, b) => b.endDateTimestampSecs.unix() - a.endDateTimestampSecs.unix()
    )
    .forEach((budget) => {
      budgetDropDownEntries.set(budget.id, budget.name);
    });

  return (
    <div className={cardWithTabsStyles.fullContainer}>
      <DropDown
        entries={budgetDropDownEntries}
        initEntryId={selectedBudget.id}
        onChange={(id: string) =>
          setBudgetAnalysisState((prevState) => {
            return { ...prevState, selectedBudgetId: id };
          })
        }
        width={316}
        color={PRIMARY_COLOR}
      ></DropDown>
      <VerticalSpacer height={15} />
      <div className={cardWithTabsStyles.reportLowerCard}>
        <AnalyzeReportTable report={report} />
      </div>
    </div>
  );
}
